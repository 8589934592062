import React from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { DeleteIconButton } from 'components/Buttons/IconButtons';
import NumberText from 'components/Main/NumberText';
import NumberInput from 'components/Form/NumberInput';

const TaskRow = React.forwardRef(({ task, index, onChange, onDelete, getSumByKey, disabled }, ref) => {
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Szczegółowe informacje' });
	const handleChange = e => {
		let { name, value } = e.target;
		value = value === '' ? value : +value;
		let _task = { ...task };
		_task[name] = value;

		switch (name) {
			case 'applied_measures':
				_task.total_application_measures = getSumByKey('applied_measures', _task.research_task_id) + value;
				break;
			case 'expenditure_incurred':
				_task.total_expenses = getSumByKey('expenditure_incurred', _task.research_task_id) + value;
				break;
			case 'approved_expenses':
				_task.total_approved = getSumByKey('approved_expenses', _task.research_task_id) + value;
				break;
			default:
				break;
		}
		onChange(_task);
	};

	return (
		<TableRow ref={ref}>
			<TableCell>
				<Box display="flex" alignItems="flex-start" justifyContent="space-between">
					e.{index + 2}. {t('Komercjalizacja')} - {task.research_task_acronym}
					<DeleteIconButton
						onClick={onDelete}
						style={{ marginLeft: 10 }}
						tooltip={t('Usuń')}
						disabled={disabled}
					/>
				</Box>
			</TableCell>
			<TableCell>
				<NumberText fixedDecimalScale={true} value={task.total_application_measures} />
			</TableCell>
			<TableCell>
				<NumberInput
					name="applied_measures"
					value={task.applied_measures}
					onChange={handleChange}
					disabled={disabled}
				/>
			</TableCell>
			<TableCell>
				<NumberText fixedDecimalScale={true} value={task.total_expenses} />
			</TableCell>
			<TableCell>
				<NumberInput
					name="expenditure_incurred"
					value={task.expenditure_incurred}
					onChange={handleChange}
					disabled={disabled}
				/>
			</TableCell>
			<TableCell>
				<NumberText fixedDecimalScale={true} value={task.total_approved} />
			</TableCell>
			<TableCell>
				<NumberInput
					name="approved_expenses"
					value={task.approved_expenses}
					onChange={handleChange}
					disabled={disabled}
				/>
			</TableCell>
		</TableRow>
	);
});
TaskRow.displayName = 'TaskRow';

export default TaskRow;
