import React, { useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box, makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import Tabs from 'components/Main/Tabs';
import Applications from './Applications';
import Settlements from './Settlements';
import DetailedInformations from './DetailedInformations';
import Accordions from 'components/Main/Accordions';

const useStyles = makeStyles(() => ({
	tabs: {
		overflowX: 'auto',
		'& > div': {
			minWidth: 1300,
		}
	},
	accordionBG: {
		padding: '0 15px',
	}
}));

const Management = () => {
	const classes = useStyles();
	const { t } = useTranslation(null, { keyPrefix: 'Zarządzanie WIB - Wniosek' });
	const history = useHistory();
	const { search } = useLocation();
	const tab = new URLSearchParams(search).get('tab');
	const breadcrumbs = [
		{ title: t('Zarządzanie WIB'), to: '' },
		{ title: t('Lista okresów sprawozdawczych') },
	];
	const tabs = [
		{
			value: 'request_transfer',
			label: t('Wniosek o przekazanie środków finansowych'),
			children: <Applications />
		},
		{
			value: 'settlements',
			label: t('Rozliczenie środków finansowych'),
			children: <Settlements />
		},
		{
			value: 'detailed_informations',
			label: t('Szczegółowe informacje o sposobie wydatkowania środków'),
			children: <DetailedInformations />
		},
	];

	const items = [
		{
			title: t('Wniosek o przekazanie środków finansowych'),
			child: <Applications />
		},
		{
			title: t('Rozliczenie środków finansowych'),
			child: <Settlements />
		},
		{
			title: t('Szczegółowe informacje o sposobie wydatkowania środków'),
			child: <DetailedInformations />
		},
	];

	useEffect(() => {
		switch (tab) {
			case 'settlements':
				document.title = 'WIB - Koszty zarządzania - Rozliczenie środków finansowych';
				break;
			case 'detailed_informations':
				document.title = 'WIB - Koszty zarządzania - Szczegółowe informacje o sposobie wydatkowania środków';
				break;
			default:
				document.title = 'WIB - Koszty zarządzania - Wnioski o przekazanie środków finansowych';
				break;
		}
	}, [tab]);

	const handleChange = value => {
		history.replace({ search: `tab=${value}` });
	};

	return (
		<>
			<Header title={t('Koszty zarządzania - Okresy sprawozdawcze')} />
			<Breadcrumbs breadcrumbs={breadcrumbs} />
			<Box className={classes.tabs}>
				{window.isMobile
					? <Accordions
						items={items}
						TransitionProps={{ unmountOnExit: true }}
						className={classes.accordionBG}
					/>
					: <Tabs
						tabs={tabs}
						defaultValue={tab}
						onChange={handleChange}
					/>
				}

			</Box>
		</>
	);
};

export default Management;
