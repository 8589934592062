import React, {
	useCallback,
	useContext,
	useEffect,
	useMemo,
	useState,
} from 'react';
import { useHistory, useParams, withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAutosave } from 'react-autosave';
import _ from 'lodash';

import API from 'apis/API';
import Routes from 'router/Routes';
import useCheckPermissions from 'utils/useCheckPermissions';
import AuthContext from './AuthContext';
import ResearchTaskReportContext from './ResearchTaskReportContext';

const ResearchTaskReportBContext = React.createContext(null);

const getBudgetColVal = (key, row, flatRate) => {
	let val = 0;
	switch (key) {
		case 'col_7':
			val = getBudgetCol7Value(row);
			break;
		case 'col_8':
			val = flatRate;
			break;
		case 'col_9':
			val = getBudgetCol9Value(row, flatRate);
			break;
		case 'col_10':
			val = getBudgetCol7Value(row) + getBudgetCol9Value(row, flatRate);
			break;
	}
	return val;
};

const getBudgetCol7Value = (row) =>
	row.col_2 + row.col_3 + row.col_4 + row.col_5 + row.col_6;
const getBudgetCol9Value = (row, flatRate) =>
	((row.col_2 + row.col_3 + row.col_6) * flatRate) / 100;

const getBudgetSum = (data) => {
	let keys = {
		col_2: 0,
		col_3: 0,
		col_4: 0,
		col_5: 0,
		col_6: 0,
		col_7: 0,
		col_8: 0,
		col_9: 0,
		col_10: 0,
	};
	let sum = _.mapValues(keys, (val, key) =>
		_.sumBy(data, (item) => item[key])
	);
	sum['col_8'] = data?.[0]?.col_8;
	sum['col_10'] = parseFloat(sum['col_10'].toFixed(2));
	return sum;
};

export const Provider = ({ children }) => {
	const history = useHistory();
	const { task_id, id } = useParams();
	const { logOut } = useContext(AuthContext);
	const { selectedLanguage, isPreview, mainInfo, setMainInfo, researchTask } =
		useContext(ResearchTaskReportContext);
	const canCreate = useCheckPermissions('can_create_report_b', true);
	const canSendAcceptance = useCheckPermissions(
		'can_send_report_b_for_acceptance',
		true
	);
	const canAccept = useCheckPermissions('can_accept_reject_report_b', true);
	const canView = useCheckPermissions('can_view_all_reports_b', true);
	const disabledInput = isPreview || (!canCreate && canAccept);

	const defaultData = {
		task_estimates : [],
		expenses: {
			data: {
				entities: [],
				sum: {},
				manage_entity_cost: {},
			},
			annotations: '',
			manage_entity_total_eligible_cost: 0,
			ineligible_cost: 0,
			user_ineligible_costs: 0,
		},
		cumulative_expenses: {
			entities: [],
			sum: {},
		},
		research_equipment_list: {
			data: [],
			total_cost: 0,
		},
		changed_information: {
			made_changes_required_acceptance: 1,
			description_of_changes: '',
			payments_schedule: {
				total: { value: 0 },
				values: {},
			},
			schedule_reason_of_change: '',
			budget_before: [],
			budget_before_sum: {},
			budget_offset: [],
			budget_offset_sum: {},
			budget_offset_description: '',
			budget_after: [],
			budget_after_sum: {},
		},
		expenses_statements: {
			sum: {
				total_direct_cost: 0,
				flat_rate: 0,
				total_indirect_cost: 0,
				total: 0,
				total_interest: 0,
			},
			salary: {
				entities: [],
				sum: {
					netto_amount: 0,
					vat_amount: 0,
					eligible_amount: 0,
				},
			},
			equipment_costs: {
				entities: [],
				sum: {
					netto_amount: 0,
					vat_amount: 0,
					eligible_amount: 0,
				},
			},
			external_service_costs: {
				entities: [],
				sum: {
					netto_amount: 0,
					vat_amount: 0,
					eligible_amount: 0,
				},
			},
			other_direct_costs: {
				entities: [],
				sum: {
					netto_amount: 0,
					vat_amount: 0,
					eligible_amount: 0,
				},
			},
		},
		quaestor: '',
		leader: '',
		representative: '',
		complete_date: new Date(),
	};
	const [status, setStatus] = useState('draft');
	const [formData, setFormData] = useState({
		pl: _.cloneDeep(defaultData),
		en: _.cloneDeep(defaultData),
	});
	const [errors, setErrors] = useState(null);
	const [saving, setSaving] = useState(false);
	const [acceptedReports, setAcceptedReports] = useState([]);

	useEffect(() => {
		if (!canCreate && !canSendAcceptance && !canAccept && !canView) {
			return logOut();
		}

		if (!id) return;

		API.researchTasks.report
			.getAcceptedReports(task_id, id)
			.then((res) => setAcceptedReports(res.data?.data));

		API.researchTasks.report.show(id).then((res) => {
			const _data = res.data.data;
			setMainInfo(_.omit(_data, 'data'));
			setFormData((prev) => {
				if (!_.isEmpty(_data.data?.pl)) prev.pl = _data.data.pl;
				if (!_.isEmpty(_data.data?.en)) prev.en = _data.data.en;
				return { ...prev };
			});
			setStatus(_data.status);
		});
	}, [id]);

	useEffect(() => {
		if (selectedLanguage === 'pl' || formData.en?.disable_grab_all) return;
		setFormData((prev) => ({ ...prev, en: _.cloneDeep(prev.en || prev.pl) }));
	}, [selectedLanguage]);

	useEffect(() => {
		if (id) return;
		init();
	}, [researchTask]);

	useEffect(() => {
		const data = formData[selectedLanguage].changed_information;
		if (!data?.budget_before) return;

		const budgetAfter = _.map(data.budget_before, (value, key) => ({
			..._.mergeWith(
				_.omit({ ...value }, ['title', 'col_8']),
				{ ...data.budget_offset[key] },
				_.add
			),
		}));

		// onChange(`${selectedLanguage}.changed_information.budget_after`, budgetAfter);
		// onChange(`${selectedLanguage}.changed_information.budget_after_sum`, getBudgetSum(budgetAfter));
		onChange('pl.changed_information.budget_after', budgetAfter);
		onChange(
			'pl.changed_information.budget_after_sum',
			getBudgetSum(budgetAfter)
		);
		onChange('en.changed_information.budget_after', budgetAfter);
		onChange(
			'en.changed_information.budget_after_sum',
			getBudgetSum(budgetAfter)
		);
	}, [formData[selectedLanguage].changed_information?.budget_offset]);

	useEffect(() => {
		// for table 1 and table 2
		let _entities = (
			[researchTask?.entities?.leader].concat(
				researchTask?.entities?.consortium_member
			) || []
		).map((entity) => {
			let _entity = {
				entity_name: entity.entity_name,
				entity_id: entity.id,
			};
			return _entity;
		});

		setFormData((prev) => {

			// table 1
			_.set(prev, 'pl.expenses.data.entities', _.cloneDeep(_entities));
			_.set(prev, 'en.expenses.data.entities', _.cloneDeep(_entities));
			// table 2
			_.set(
				prev,
				'pl.cumulative_expenses.entities',
				_.cloneDeep(_entities)
			);
			_.set(
				prev,
				'en.cumulative_expenses.entities',
				_.cloneDeep(_entities)
			);
			// table 4
			_.set(
				prev,
				'pl.expenses_statements.sum.flat_rate',
				researchTask.flat_rate
			);
			_.set(
				prev,
				'en.expenses_statements.sum.flat_rate',
				researchTask.flat_rate
			);
			return { ...prev };
		});
		if (id) return;

		// table 4
		setFormData((prev) => {
			let entitiesWithMember = getEntities(true);
			let entitiesWithoutMember = getEntities();

			_.set(prev, 'pl.task_estimates', _.cloneDeep(researchTask.task_estimates)),
			_.set(prev, 'en.task_estimates', _.cloneDeep(researchTask.task_estimates)),

			_.set(
				prev,
				'pl.expenses_statements.salary.entities',
				_.cloneDeep(entitiesWithMember)
			);
			_.set(
				prev,
				'en.expenses_statements.salary.entities',
				_.cloneDeep(entitiesWithMember)
			);
			_.set(
				prev,
				'pl.expenses_statements.equipment_costs.entities',
				_.cloneDeep(entitiesWithoutMember)
			);
			_.set(
				prev,
				'en.expenses_statements.equipment_costs.entities',
				_.cloneDeep(entitiesWithoutMember)
			);
			_.set(
				prev,
				'pl.expenses_statements.external_service_costs.entities',
				_.cloneDeep(entitiesWithoutMember)
			);
			_.set(
				prev,
				'en.expenses_statements.external_service_costs.entities',
				_.cloneDeep(entitiesWithoutMember)
			);
			_.set(
				prev,
				'pl.expenses_statements.other_direct_costs.entities',
				_.cloneDeep(entitiesWithoutMember)
			);
			_.set(
				prev,
				'en.expenses_statements.other_direct_costs.entities',
				_.cloneDeep(entitiesWithoutMember)
			);
			return { ...prev };
		});
	}, [researchTask?.entities]);

	const init = () => {
		let _formData = _.cloneDeep(formData);

		// table 3 - payment schedule.
		let paymentsSchedule = researchTask.payments_schedule;
		if (paymentsSchedule) {
			_.set(
				_formData,
				'pl.changed_information.payments_schedule',
				_.cloneDeep(paymentsSchedule)
			);
			_.set(
				_formData,
				'en.changed_information.payments_schedule',
				_.cloneDeep(paymentsSchedule)
			);
		}

		// table 3 - budget table
		const keys = [
			'title',
			'col_2',
			'col_3',
			'col_4',
			'col_5',
			'col_6',
			'col_7',
			'col_8',
			'col_9',
			'col_10',
			'entity_id',
		];
		const data = {
			budget_before: [],
			budget_offset: [],
		};

		let entitiesWithoutMember = getEntities(false);

		let taskEstimates = formData[selectedLanguage].task_estimates ?? researchTask.task_estimates;

		_.keys(data).map((dataKey) => {
			taskEstimates?.map((item) => {

				item.title = _.find(entitiesWithoutMember , {entity_id: item.entity_id}).entity_name;

				let _item = { ...item };
				if (dataKey === 'budget_offset') {
					_.tail(keys, 'title').map((key) => (_item[key] = 0));
				}

				['col_7', 'col_8', 'col_9', 'col_10'].map((key) => {
					_item[key] = getBudgetColVal(
						key,
						_item,
						researchTask.flat_rate
					);
				});

				if (dataKey !== 'budget_offset') {
					_item['col_9'] = item.col_7;
					_item['col_10'] = _item['col_7'] + _item['col_9'];
				}

				data[dataKey].push(_.pick(_item, keys));
			});
		});

		let budgetBeforeSum = getBudgetSum(data.budget_before);
		let budgetOffsetSum = getBudgetSum(data.budget_offset);

		_.set(
			_formData,
			'pl.changed_information.budget_before',
			data.budget_before
		);
		_.set(
			_formData,
			'en.changed_information.budget_before',
			data.budget_before
		);
		_.set(
			_formData,
			'pl.changed_information.budget_offset',
			data.budget_offset
		);
		_.set(
			_formData,
			'en.changed_information.budget_offset',
			data.budget_offset
		);
		_.set(
			_formData,
			'pl.changed_information.budget_before_sum',
			budgetBeforeSum
		);
		_.set(
			_formData,
			'en.changed_information.budget_before_sum',
			budgetBeforeSum
		);
		_.set(
			_formData,
			'pl.changed_information.budget_offset_sum',
			budgetOffsetSum
		);
		_.set(
			_formData,
			'en.changed_information.budget_offset_sum',
			budgetOffsetSum
		);
		setFormData(_formData);

		// When creating new row, save empty data and edit it.
		if (!researchTask?.id) return;
		let entitiesWithMember = getEntities(true);

		_.set(
			_formData,
			'pl.expenses_statements.salary.entities',
			_.cloneDeep(entitiesWithMember)
		);
		_.set(
			_formData,
			'en.expenses_statements.salary.entities',
			_.cloneDeep(entitiesWithMember)
		);
		_.set(
			_formData,
			'pl.expenses_statements.equipment_costs.entities',
			_.cloneDeep(entitiesWithoutMember)
		);
		_.set(
			_formData,
			'en.expenses_statements.equipment_costs.entities',
			_.cloneDeep(entitiesWithoutMember)
		);
		_.set(
			_formData,
			'pl.expenses_statements.external_service_costs.entities',
			_.cloneDeep(entitiesWithoutMember)
		);
		_.set(
			_formData,
			'en.expenses_statements.external_service_costs.entities',
			_.cloneDeep(entitiesWithoutMember)
		);
		_.set(
			_formData,
			'pl.expenses_statements.other_direct_costs.entities',
			_.cloneDeep(entitiesWithoutMember)
		);
		_.set(
			_formData,
			'en.expenses_statements.other_direct_costs.entities',
			_.cloneDeep(entitiesWithoutMember)
		);

		onAutoSave({ ...mainInfo, data: _formData, status: 'draft' });
	};

	const getEntities = (withMember = false) => {
		let _entities = (
			[researchTask?.entities?.leader].concat(
				researchTask?.entities?.consortium_member
			) || []
		).map((entity) => {
			let _entity = {
				entity_name: entity.entity_name,
				entity_id: entity.id,
				sum: {
					netto_amount: 0,
					vat_amount: 0,
					eligible_amount: 0,
				},
			};
			if (withMember) {
				_entity.members = [];
			} else {
				_entity.data = [];
			}
			return _entity;
		});
		return _entities;
	};

	const onChange = (key, value, customizer = null, disableGrab = false) => {
		setFormData((prev) => {
			_.setWith(prev, key, value, customizer);

			if (selectedLanguage === 'en' && disableGrab) {
				prev.en.disable_grab_all = true;
			}

			return { ...prev };
		});
	};

	const onCancel = () => {
		history.push(Routes.ResearchTasks.Reports.List(task_id));
	};

	const saveCallback = (isDraft) => (res) => {
		try {
			setSaving(false);
			if (!_.isEmpty(res.data?.errors)) {
				if (isDraft) {
					toast.success('Wersja robocza została zapisana!');
				} else {
					toast.error(
						'Nie wszystkie pola spełniają wymagania. Popraw błędy i spróbuj ponownie.'
					);
				}
				setErrors((prev) => {
					prev = {};
					_.mapKeys(res.data.errors, (value, key) => {
						_.set(prev, key, value);
					});

					return prev;
				});
				return;
			}
			setErrors(null);
			toast.success('Raport został zapisany pomyślnie');
			if (!isDraft) onCancel();
		} catch (error) {
			setSaving(false);
			toast.error(
				'Nie wszystkie pola spełniają wymagania. Popraw błędy i spróbuj ponownie.'
			);
		}
	};

	const isAnyDraftRowPresent = () => {
		const reportBInputNodeList = document.querySelectorAll(
			'#research-task-report-b input:not([hidden])'
		);
		const reportBInputElems = [].slice
			.call(reportBInputNodeList)
			.splice(0, reportBInputNodeList.length - 1, 1);

		const reportBEquipmentInputElems = document.querySelectorAll(
			'#research-task-equipment input'
		);

		if (
			reportBInputElems.length > 0 ||
			reportBEquipmentInputElems.length > 0
		) {
			const reportBBorderNodeList = document.querySelectorAll(
				'#research-task-report-b fieldset'
			);
			const reportBBorderElems = [].slice
				.call(reportBBorderNodeList)
				.splice(0, reportBBorderNodeList.length - 1, 1);

			var reportB = 0,
				reportBLength = reportBBorderElems.length;
			for (; reportB < reportBLength; reportB++) {
				reportBBorderElems[reportB].style.borderColor = '#E51A1A';
			}

			const reportBEquipmentBorderElems = document.querySelectorAll(
				'#research-task-equipment fieldset'
			);

			var reportBEquipment = 0,
				reportBEquipmentLength = reportBEquipmentBorderElems.length;
			for (
				;
				reportBEquipment < reportBEquipmentLength;
				reportBEquipment++
			) {
				reportBEquipmentBorderElems[
					reportBEquipment
				].style.borderColor = '#E51A1A';
			}

			toast.error('Dane powinny zostać zatwierdzone.');

			return true;
		}
		return false;
	};

	const onSave = (comments) => {
		if (isAnyDraftRowPresent()) {
			return;
		}

		setSaving(true);
		(status === 'waiting-for-acceptance'
			? API.researchTasks.report.reject
			: API.researchTasks.report.update)(
			// eslint-disable-next-line no-unexpected-multiline
			{ ...mainInfo, comments, data: formData },
			id
		).then(saveCallback(status !== 'waiting-for-acceptance'));
	};

	const onClickPrimary = (comments) => {
		setSaving(true);
		(status === 'draft'
			? API.researchTasks.report.sendAcceptance
			: API.researchTasks.report.accept)(
			// eslint-disable-next-line no-unexpected-multiline
			{ ...mainInfo, comments, data: formData },
			id
		).then(saveCallback());
	};

	const autoSave = (data) => {
		if (
			isPreview ||
			data.status !== 'draft' ||
			!canCreate ||
			saving ||
			status !== 'draft' ||
			(!canCreate && status === 'draft')
		)
			return;

		// if (isAnyDraftRowPresent()) {
		// 	return;
		// }

		setSaving(true);
		(id ? API.researchTasks.report.update : API.researchTasks.report.store)(
			// eslint-disable-next-line no-unexpected-multiline
			data,
			id
		).then((res) => {
			setSaving(false);
			if (id) return;
			history.push(
				Routes.ResearchTasks.Reports.Edit(
					task_id,
					'type_b',
					res.data.data.id
				)
			);
		});
	};

	const onAutoSave = useCallback(_.debounce(autoSave, 1000), []);

	const autoSaveData = useMemo(
		() => ({ ...mainInfo, data: formData }),
		[mainInfo, formData]
	);
	useAutosave({
		data: autoSaveData,
		onSave: onAutoSave,
		interval: 300000,
		saveOnUnmount: false,
	});

	const value = {
		mainInfo,
		acceptedReports,
		formData,
		errors: {
			period_from: errors?.period_from,
			period_to: errors?.period_to,
			...errors?.data?.[selectedLanguage],
		},
		saving,
		status,
		selectedLanguage,
		isPreview,
		disabledInput,
		onChange,
		onSave,
		onClickPrimary,
		onCancel,
		getBudgetColVal,
		getBudgetSum,
	};

	return (
		<ResearchTaskReportBContext.Provider value={value}>
			{children}
		</ResearchTaskReportBContext.Provider>
	);
};

export const ResearchTaskReportBContextProvider = withRouter(Provider);

export default ResearchTaskReportBContext;
