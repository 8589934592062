import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { makeStyles, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import DateInput from 'components/Form/DateInput';
import FormInput from 'components/Form/FormInput';
import NumberInput from 'components/Form/NumberInput';
import ResearchTaskContext from 'context/ResearchTaskContext';
import Select from 'components/Form/Select';
import Typography from 'components/Main/Typography';

const useStyles = makeStyles(theme => ({
	root: {
		paddingRight: theme.spacing(45),
		[theme.breakpoints.down('md')]: {
			paddingRight: 0,
		},
		'& > .MuiTypography-root': {
			marginBottom: theme.spacing(3),
		},
	},
}));

const GeneralInfoTab = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { search } = useLocation();
	const isPreview = new URLSearchParams(search).get('preview') === 'true';
	const isConsortiumOptions = [
		{ value: true, label: t('Tak') },
		{ value: false, label: t('Nie') },
	];
	const formInputs = [
		{
			name: 'title',
			title: t('Tytuł Zadania Badawczego')
		},
		{
			name: 'acronym',
			title: t('Akronim Zadania Badawczego')
		},
		{
			name: 'number',
			title: t('Numer zadania')
		},
		{
			name: 'science_field',
			title: t('Dziedzina nauki'),
			helperText: t('Dziedzina nauki według rozporządzenia Ministra Nauki i Szkolnictwa Wyższego z 20.09.2018 r. w sprawie dziedzin nauki i dyscyplin naukowych oraz dyscyplin artystycznych (Dz. U. z 2018 r., poz. 1818)')
		},
		{
			name: 'oecd_science_field',
			title: t('Dziedzina nauki według OECD'),
			helperText: t('Dziedzina nauki według Klasyfikacji dziedzin i dyscyplin OECD'),
		},
		{
			name: 'keywords',
			title: t('Słowa kluczowe'),
			helperText: t('Słowa kluczowe bezpośrednio związane i precyzyjnie opisujące przedmiot zadania badawczego (maksymalnie 10)'),
			type: 'textarea'
		},
		{
			name: 'research_problem',
			title: t('Problem badawczy'),
			helperText: t('Problem badawczy (wskazanie wyłącznie jednego dominującego problemu)'),
			type: 'textarea'
		},
		{
			name: 'funding_amount',
			title: t('Kwota finansowania'),
			helperText: t('Wnioskowana kwota finansowania zadania badawczego ze środków Funduszu (w PLN)'),
			disabled: true,
			type: 'number'
		},
		{
			name: 'is_consortium',
			title: t('Czy Konsorcjum'),
			type: 'select',
			options: isConsortiumOptions,
		},
		{
			name: 'management_unit_name',
			title: t('Nazwa Podmiotu zarządzającego WIB'),
			disabled: true,
		},
		{
			name: 'funding_number',
			title: t('Numer wniosku o finansowanie'),
		},
		{
			name: 'financial_agreement_number',
			title: t('Numer Umowy o finansowanie'),
		},
		{
			name: 'financial_agreement_signing_date',
			title: t('Data podpisania Umowy'),
			type: 'date',
		},
		{
			name: 'leader_name',
			title: t('Imię i nazwisko Lidera Zespołu Badawczego'),
		},
		{
			name: 'account_number',
			title: t('Aktualny numer rachunku bankowego'),
		},
	];
	const { formData, errors, onChange } = useContext(ResearchTaskContext);
	const classes = useStyles();
	const [data, setData] = useState(formData.general_info);

	useEffect(() => {
		if (_.isEqual(data, formData.general_info)) return;
		setData(formData.general_info);
	}, [formData.general_info]);

	useEffect(() => {
		if (_.isEqual(data, formData.general_info)) return;
		onChange('general_info', data);
	}, [data]);

	const handleChange = e => {
		const { name, value } = e.target;
		setData(prev => ({ ...prev, [name]: value }));
	};

	const renderForm = (item, index) => {
		let input = <></>;

		switch (item?.type) {
			case 'select':
				input = <Select
					key={index}
					title={item.title}
					name={item.name}
					options={item.options}
					value={data[item.name]}
					onChange={handleChange}
					error={errors?.general_info?.[item.name]}
					inputProps={{ 'aria-required': true }}
					displayEmpty
					disabled={isPreview}/>;
				break;
			case 'date':
				input = <DateInput
					key={index}
					title={item.title}
					name={item.name}
					value={data[item.name] || null}
					onChange={handleChange}
					error={errors?.general_info?.[item.name]}
					inputProps={{ 'aria-required': true }}
					disabled={isPreview}
				/>;
				break;
			case 'number':
				input = <NumberInput
					key={index}
					inputType="form"
					title={item.title}
					helperText={item?.helperText}
					value={data[item.name] || 0}
					error={errors?.general_info?.[item.name]}
					inputProps={{ 'aria-required': true }}
					disabled={item?.disabled}
				/>;
				break;
			default:
				input = <FormInput
					key={index}
					title={item.title}
					helperText={item?.helperText}
					type={item.type || 'input'}
					multiline={item.type === 'textarea'}
					minRows={5}
					maxRows={5}
					alignItems={item.type === 'textarea' ? 'flex-start' : 'center'}
					name={item.name}
					value={data[item.name]}
					onChange={handleChange}
					error={errors?.general_info?.[item.name]}
					disabled={item?.disabled || isPreview}
					inputProps={{ 'aria-required': true }}
				/>;
		}
		return input;
	};

	return (
		<Box className={classes.root}>
			<Typography variant="h3">{t('Informacje ogólne')}</Typography>
			{formInputs.map(renderForm)}
		</Box>
	);
};

export default GeneralInfoTab;
