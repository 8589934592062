import React, { useContext, useEffect, useState } from 'react';
import { Box, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import _ from 'lodash';

import API from 'apis/API';
import Routes from 'router/Routes';
import { formatDate } from 'utils/formatters';
import useCheckPermissions from 'utils/useCheckPermissions';
import AuthContext from 'context/AuthContext';
import Header from 'components/Main/Header';
import Breadcrumbs from 'components/Buttons/Breadcrumbs';
import IconButton, { DeleteIconButton, EditIconButton } from 'components/Buttons/IconButtons';
import PaginatedTable from 'components/PaginatedTable';
import Progress from 'components/Main/Progress';
import { NewsIcon, VisibleIcon } from 'assets/svg';

import FilterRow from './FilterRow';

const ResearchTasks = () => {
	const { t } = useTranslation(null, { keyPrefix: 'Zadania badawcze' });
	const { user } = useContext(AuthContext);
	const assignedTasks = (user?.assigned_tasks || []);
	const canAddDrafts = useCheckPermissions('can_add_research_task_drafts', true);
	const canPublish = useCheckPermissions('can_publish_research_task', true);
	const canAccessReport = useCheckPermissions([
		'can_view_all_reports_a',
		'can_view_assigned_reports_a',
		'can_view_assigned_reports_b',
		'can_view_all_reports_b',
		'can_create_report_a',
		'can_send_report_a_for_acceptance',
		'can_accept_reject_report_a',
		'can_create_report_b',
		'can_send_report_b_for_acceptance',
		'can_accept_reject_report_b',
		'can_remove_own_reports_a',
		'can_remove_own_reports_b',
		'can_remove_all_reports_a',
		'can_remove_all_reports_b',
	], true);

	const breadcrumbs = [
		{ title: t('Zadania badawcze'), to: '' },
		{ title: t('Lista zadań badawczych') },
	];
	const columns = [
		// { title: 'LP.', name: 'id', width: 150 },
		{ title: t('Akronim'), name: 'acronym', width: 150 },
		{ title: t('Pełna nazwa'), name: 'title' },
		{ title: t('Status'), name: 'status' },
		{ title: t('Okres powstania'), name: 'created_at', width: 400 },
		{
			title: t('Akcje'),
			name: 'action',
			width: 100,
			disableSort: true
		},
	];
	const [loading, setLoading] = useState(false);
	const [data, setData] = useState(null);
	const [totalPagesCount, setTotalPagesCount] = useState(0);
	const [params, setParams] = useState({
		limit: 20,
		page: 1,
		column: 'id',
		direction: 'desc',
		filters: {}
	});

	useEffect(() => document.title = 'WIB - Zadania Badawcze', []);

	useEffect(() => loadFilteredData(), [params]);

	const handleChangeParams = _params => {
		const picked = _.pick(params, ['column', 'direction', 'page']);
		if (_.isEqual(picked, _params)) return;

		setParams({ ...params, ..._params });
	};

	const handleChangePerPage = e => {
		setParams(prev => ({ ...prev, limit: e.target.value }));
	};

	const handleChangeFilters = filters => {
		setParams(prev => {
			if (_.isEqual(prev.filters, _.pickBy(filters))) return prev;

			return { ...prev, filters };
		});
	};

	const loadFilteredData = () => {
		if (loading) return;
		setLoading(true);
		API.researchTasks.index(params).then(res => {
			setLoading(false);
			setData(res.data?.data);
			setTotalPagesCount(res.data?.last_page);
		});
	};

	const handleDelete = id => () => {
		API.researchTasks.delete(id).then(res => {
			if (res.data.code === 200) {
				loadFilteredData();
				return toast.success('Zadanie badawcze zostało usunięte!');
			}

			toast.error('Nie znaleziono zadanie badawcze');
		});
	};

	const checkCanViewReport = task => {
		// draft tasks can't have reports.
		if(task.status === 'draft' || _.size(canAccessReport || []) < 1) return false;

		// If can view or remove all reports.
		if(_.size(_.intersection(canAccessReport || [], [
			'can_view_all_reports_a',
			'can_view_all_reports_b',
			'can_remove_all_reports_a',
			'can_remove_all_reports_b',
		])) > 0) return true;

		if(_.indexOf(assignedTasks, task.id) > -1) return true;
		return false;
	};

	const ReportButton = React.forwardRef((props, ref) => (
		<IconButton ref={ref} tooltip={t('Raportowanie')} {...props}>
			<NewsIcon alt="Ikona raportowania" />
		</IconButton>
	));
	ReportButton.displayName = 'ReportButton';

	const renderRows = () => (
		data.map((row, index) => (
			<TableRow key={index}>
				{/*<TableCell>{row.id}</TableCell>*/}
				<TableCell style={row.status === 'draft' ? { color: '#757983' } : null}>{row.acronym}</TableCell>
				<TableCell style={row.status === 'draft' ? { color: '#757983' } : null}>{row.title}</TableCell>
				<TableCell>{row.status === 'published' ? t('Zapisany') : t('Szkic')}</TableCell>
				<TableCell>{formatDate(row.created_at)}</TableCell>
				<TableCell>
					<Box display="flex" justifyContent="flex-end">
						<Link to={(row.status === 'published' ? Routes.ResearchTasks.EditPublished : Routes.ResearchTasks.Edit)(row.id, true)}>
							<IconButton tooltip={t('Podgląd')}>
								<VisibleIcon alt="Ikona podglądu wiersza" />
							</IconButton>
						</Link>
						{(canAddDrafts || canPublish) &&
							<Link to={(row.status === 'published' ? Routes.ResearchTasks.EditPublished : Routes.ResearchTasks.Edit)(row.id)}>
								<EditIconButton tooltip={t('Edytuj')} />
							</Link>
						}
						{checkCanViewReport(row) &&
							<Link to={Routes.ResearchTasks.Reports.List(row.id)}>
								<ReportButton />
							</Link>
						}
						{canAddDrafts &&
							<DeleteIconButton tooltip={t('Usuń')} onClick={handleDelete(row.id)} />
						}
					</Box>
				</TableCell>
			</TableRow>
		)));

	return (
		<>
			<Header
				title={t('Lista zadań badawczych')}
				perPageCount={params.limit}
				onChangeCount={handleChangePerPage}
				createTitle={t('Dodaj zadanie')}
				createPath={canAddDrafts ? Routes.ResearchTasks.Create : null}
			/>
			<Breadcrumbs breadcrumbs={breadcrumbs} />

			{!data
				? <Progress status={true} />
				: <PaginatedTable
					columns={columns}
					exportEndpoint={API.researchTasks.exportList}
					filename={t('Lista zadań badawczych')}
					totalPagesCount={totalPagesCount}
					onChangeFilters={handleChangeParams}
					renderRows={renderRows}
					filterRow={<FilterRow onChange={handleChangeFilters} />}
				/>}
		</>
	);
};

export default ResearchTasks;
